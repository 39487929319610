import { RootState } from "../app/store"
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import { AxiosInstance } from "axios"

export type Dataset = {
  label: string
  data: number[]
  borderColor: string
  backgroundColor: string
  fill: boolean
  tension: number
  yAxisID: string
  radius: number
}

export type Chart = {
  labels: string[]
  datasets: Dataset[]
}

export const defaultChart: Chart = {
  labels: [],
  datasets: [],
}

export const chartDataProperties = [
  {
    key: "percent",
    label: "Battery Level (%)",
    borderColor: "rgb(91, 194, 54)",
    backgroundColor: "rgb(91, 194, 54, 0.4)",
    fill: true,
    tension: 0.4,
    yAxisID: "y",
    radius: 0,
    strokeWidth: 2,
  },
  {
    key: "production",
    label: "Production (W)",
    borderColor: "rgb(255, 200, 0)",
    backgroundColor: "rgb(30,30,30,0.2)",
    fill: false,
    tension: 0.4,
    yAxisID: "y",
    radius: 0,
    strokeWidth: 2,
  },
  {
    key: "consumption",
    label: "Consumption (W)",
    borderColor: "rgb(220, 53, 69)",
    backgroundColor: "rgb(30,30,30,0.2)",
    fill: false,
    tension: 0.4,
    yAxisID: "y",
    radius: 0,
    strokeWidth: 2,
  },
  {
    key: "fromGrid",
    label: "Power from grid (W)",
    borderColor: "rgb(0, 123, 255)",
    backgroundColor: "rgb(30,30,30,0.2)",
    fill: false,
    tension: 0.4,
    yAxisID: "y",
    radius: 0,
    strokeWidth: 2,
  },
  {
    key: "toGrid",
    label: "Power to grid (W)",
    borderColor: "rgb(0, 210, 255)",
    backgroundColor: "rgb(30,30,30,0.2)",
    fill: false,
    tension: 0.4,
    yAxisID: "y",
    radius: 0,
    strokeWidth: 2,
  },
  {
    key: "produced",
    label: "Produced (W)",
    borderColor: "rgb(255, 200, 0)",
    backgroundColor: "rgb(30,30,30,0.2)",
    fill: false,
    tension: 0.4,
    yAxisID: "y",
    radius: 0,
    strokeWidth: 2,
  },
  {
    key: "consumed",
    label: "Consumed (W)",
    borderColor: "rgb(220, 53, 69)",
    backgroundColor: "rgb(30,30,30,0.2)",
    fill: false,
    tension: 0.4,
    yAxisID: "y",
    radius: 0,
    strokeWidth: 2,
  },
]

export const fetchBatteryChartData = createAsyncThunk(
  "chart/fetchBatteryChartData",
  async (data: { axios: AxiosInstance; url: string }) => {
    const response = await data.axios.get(data.url)
    return response.data
  }
)

export const fetchDailySumChartData = createAsyncThunk(
  "chart/fetchDailySumChartData",
  async (data: { axios: AxiosInstance; url: string }) => {
    const response = await data.axios.get(data.url)
    return response.data
  }
)

export const fetchWattThroughputChartData = createAsyncThunk(
  "chart/fetchWattThroughputChartData",
  async (data: { axios: AxiosInstance; url: string }) => {
    const response = await data.axios.get(data.url)
    return response.data
  }
)

export const fetchGridThroughputChartData = createAsyncThunk(
  "chart/fetchGridThroughputChartData",
  async (data: { axios: AxiosInstance; url: string }) => {
    const response = await data.axios.get(data.url)
    return response.data
  }
)

export const ChartSlice = createSlice({
  name: "chart",
  initialState: {
    selectedChart: defaultChart,
    batteryChartData: defaultChart,
    dailySumChartData: defaultChart,
    wattThroughputChartData: defaultChart,
    gridThroughputChartData: defaultChart,
  },
  reducers: {
    setSelectedChart: (state, action: PayloadAction<Chart>) => {
      state.selectedChart = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBatteryChartData.fulfilled, (state, action) => {
      state.batteryChartData = action.payload
    })
    builder.addCase(fetchDailySumChartData.fulfilled, (state, action) => {
      state.dailySumChartData = action.payload
    })
    builder.addCase(fetchWattThroughputChartData.fulfilled, (state, action) => {
      state.wattThroughputChartData = action.payload
    })
    builder.addCase(fetchGridThroughputChartData.fulfilled, (state, action) => {
      state.gridThroughputChartData = action.payload
    })
  },
})

export const { setSelectedChart } = ChartSlice.actions

export const getSelectedChart = (state: RootState) => state.Chart.selectedChart

export const getBatteryChartData = (state: RootState) =>
  state.Chart.batteryChartData

export const getDailySumChartData = (state: RootState) =>
  state.Chart.dailySumChartData

export const getWattThroughputChartData = (state: RootState) =>
  state.Chart.wattThroughputChartData

export const getGridThroughputChartData = (state: RootState) =>
  state.Chart.gridThroughputChartData

export const selectBatteryChartData = createSelector(
  getBatteryChartData,
  (data) => getChartDataFromJson(data)
)

export const selectDailySumChartData = createSelector(
  getDailySumChartData,
  (data) => getChartDataFromJson(data)
)

export const selectWattThroughputChartData = createSelector(
  getWattThroughputChartData,
  (data) => getChartDataFromJson(data)
)

export const selectGridThroughputChartData = createSelector(
  getGridThroughputChartData,
  (data) => getChartDataFromJson(data)
)

export default ChartSlice.reducer

const formatTime = (dateString: string) => {
  const date = new Date(dateString)
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
}

const getChartDataFromJson = (data: any) => {
  if (data.data === undefined) return
  const jsonData = data.data

  const keys = Object.keys(jsonData[0]).filter((key) => key !== "created_at")
  const labels = jsonData.map((item: any) => formatTime(item.created_at))

  const datasets = keys.map((key) => {
    const chartDataInfo = chartDataProperties.find((item) => item.key === key)

    return {
      label: chartDataInfo?.label,
      data: jsonData.map((item: any) => item[key]),
      borderColor: chartDataInfo?.borderColor,
      backgroundColor: chartDataInfo?.backgroundColor,
      yAxisID: chartDataInfo?.yAxisID,
      tension: chartDataInfo?.tension,
      fill: chartDataInfo?.fill,
      radius: 0,
      borderWidth: chartDataInfo?.strokeWidth ? chartDataInfo.strokeWidth : 1,
    }
  })

  return { labels, datasets }
}
