import { RootState } from "../app/store"
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit"
import { AxiosInstance } from "axios"
import {
  selectGridThroughputChartData,
  selectWattThroughputChartData,
} from "./Chart"

export type Knob = {
  min: number
  max: number
  vaLue: number
}

export type Ephemeris = {
  sunrise: string
  sunset: string
}

export type weatherInfo = {
  batteryTemperature: number
  outdoorTemperature: number
  weathercode: number
  day: string
}

export type knobData = {
  day: string
  consumption: number
  production: number
  powerFromGrid: number
  powerToGrid: number
  batteryPercent: number
  autonomyPercent: number
  batteryTemperature: number
  outdoorTemperature: number
  weatherCode: number
}

export const defaultKnob: Knob = {
  min: 0,
  max: 100,
  vaLue: 0,
}

export const fetchKnobData = createAsyncThunk(
  "knob/fetchKnobData",
  async (data: { axios: AxiosInstance; url: string }) => {
    const response = await data.axios.get(data.url)
    return response.data
  }
)

export const fetchWeatherData = createAsyncThunk(
  "knob/fetchWeatherData",
  async (data: { axios: AxiosInstance; url: string }) => {
    const response = await data.axios.get(data.url)
    return response.data
  }
)

export const knobSlice = createSlice({
  name: "knob",
  initialState: {
    knobData: {} as knobData,
    ephemeris: {} as Ephemeris,
    batteryKnob: defaultKnob,
    autonomyKnob: defaultKnob,
    consumptionKnob: defaultKnob,
    productionKnob: defaultKnob,
    powerToGridKnob: defaultKnob,
    powerFromGridKnob: defaultKnob,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchKnobData.fulfilled, (state, action) => {
      state.knobData = action.payload
    })
    builder.addCase(fetchWeatherData.fulfilled, (state, action) => {
      state.ephemeris = action.payload.data[0]
    })
  },
})

export const {} = knobSlice.actions

export const selectAllKnobData = createSelector(
  (state: RootState) => state.Knob.knobData,
  selectWattThroughputChartData,
  selectGridThroughputChartData,
  (data, wattThroughputChartData, gridThroughputChartData) => {
    if (
      !wattThroughputChartData ||
      !gridThroughputChartData ||
      Object.keys(data).length === 0
    )
      return undefined
    const knobValues = {
      batteryKnob: {
        min: 0,
        max: 100,
        vaLue: data.batteryPercent || 0,
        valueColor: "rgb(91, 194, 54)",
      },
      autonomyKnob: {
        min: 0,
        max: 100,
        vaLue: data.autonomyPercent || 0,
        valueColor: "rgb(166, 42, 168)",
      },
      consumptionKnob: {
        min: 0,
        max: +Math.max(...wattThroughputChartData.datasets[1].data).toFixed(2),
        vaLue: +data.consumption || 0,
        valueColor: "rgb(220, 53, 69)",
      },
      productionKnob: {
        min: 0,
        max: +Math.max(...wattThroughputChartData.datasets[0].data).toFixed(2),
        vaLue: data.production || 0,
        valueColor: "rgb(255, 200, 0)",
      },
      powerToGridKnob: {
        min: 0,
        max: +Math.max(...gridThroughputChartData.datasets[1].data).toFixed(2),
        vaLue: +data.powerToGrid < 0 ? 0 : data.powerToGrid,
        valueColor: "rgb(0, 210, 255)",
      },
      powerFromGridKnob: {
        min: 0,
        max: +Math.max(...gridThroughputChartData.datasets[0].data).toFixed(2),
        vaLue: data.powerFromGrid || 0,
        valueColor: "rgb(0, 123, 255)",
      },
    }
    return knobValues
  }
)

function stripephemerisTime(time: string) {
  const [hours, minutes] = time.split(":")
  const period = time.slice(-2)
  return `${hours}:${minutes} ${period}`
}

function isDaytime(currentTime: Date, sunriseTime: string, sunsetTime: string) {
  function parseTime(timeStr: string) {
    const [time, period] = timeStr.split(" ")
    const [hours, minutes] = time.split(":")
    let hours24 = parseInt(hours, 10)

    if (period.toLowerCase() === "pm" && hours24 !== 12) {
      hours24 += 12
    }
    if (period.toLowerCase() === "am" && hours24 === 12) {
      hours24 = 0
    }

    const date = new Date()
    date.setHours(hours24, parseInt(minutes, 10), 0, 0)
    return date
  }

  const sunrise = parseTime(sunriseTime)
  const sunset = parseTime(sunsetTime)

  return currentTime >= sunrise && currentTime <= sunset
}

export const selectWeatherInfo = createSelector(
  (state: RootState) => state.Knob.knobData,
  (state: RootState) => state.Knob.ephemeris,
  (knobData, ephemeris) => {
    if (
      Object.keys(knobData).length === 0 ||
      Object.keys(ephemeris).length === 0
    )
      return undefined
    const currentTime = new Date()

    const weatherInfo = {
      batteryTemperature: knobData.batteryTemperature,
      outdoorTemperature: knobData.outdoorTemperature,
      weathercode: knobData.weatherCode,
      day: knobData.day,
      sunrise: stripephemerisTime(ephemeris.sunrise),
      sunset: stripephemerisTime(ephemeris.sunset),
      daytime: isDaytime(currentTime, ephemeris.sunrise, ephemeris.sunset),
    }

    return weatherInfo
  }
)

export default knobSlice.reducer
