import { RootState } from "../app/store"
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"

export type PanelType = "main" | "calendar"

export type UIStates = {
  enlargedChart: boolean
  selectedPanel: PanelType
}

const initialState: UIStates = {
  enlargedChart: false,
  selectedPanel: "main",
}

export const uiStateSlice = createSlice({
  name: "UIState",
  initialState,
  reducers: {
    setEnlargedChart: (state, action: PayloadAction<boolean>) => {
      state.enlargedChart = action.payload
    },
    setSelectedPanel: (state, action: PayloadAction<PanelType>) => {
      state.selectedPanel = action.payload
    },
  },
  extraReducers: (builder) => {},
})

export const getEnlargedChart = (state: RootState) =>
  state.UIState.enlargedChart

export const getSelectedPanel = (state: RootState) =>
  state.UIState.selectedPanel

export const { setEnlargedChart, setSelectedPanel } = uiStateSlice.actions

export const selectOptions = createSelector(
  getEnlargedChart,
  (enlargedChart) => {
    const documentStyle = getComputedStyle(document.documentElement)
    const textColor = documentStyle.getPropertyValue("--text-color")
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    )
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border")
    const options = {
      maintainAspectRatio: enlargedChart,
      aspectRatio: enlargedChart ? 3 : 1,

      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    }
    return options
  }
)

export default uiStateSlice.reducer
