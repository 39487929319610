import { useState, useEffect } from "react"

import "../style/Mainpanel.css"
import "primereact/resources/themes/arya-blue/theme.css"
import "primeicons/primeicons.css"
import "weather-react-icons/lib/css/weather-icons.css"
import { openWeatherWMOToEmoji } from "@akaguny/open-meteo-wmo-to-emoji"

import { useAppSelector, useAppDispatch } from "../app/hooks"

import {
  getEnlargedChart,
  selectOptions,
  setEnlargedChart,
} from "../features/UIState"
import {
  selectBatteryChartData,
  selectDailySumChartData,
  selectGridThroughputChartData,
  selectWattThroughputChartData,
} from "../features/Chart"

import { Nullable } from "primereact/ts-helpers"
import { Chart } from "primereact/chart"
import { Knob } from "primereact/knob"
import { selectAllKnobData, selectWeatherInfo } from "../features/Knob"

const MainPanel = () => {
  const dispatch = useAppDispatch()

  const batteryData = useAppSelector(selectBatteryChartData)
  const dailySumData = useAppSelector(selectDailySumChartData)
  const wattThroughputData = useAppSelector(selectWattThroughputChartData)
  const gridThroughputData = useAppSelector(selectGridThroughputChartData)
  const knobData = useAppSelector(selectAllKnobData)
  const weatherInfo = useAppSelector(selectWeatherInfo)

  const enlargedChart = useAppSelector(getEnlargedChart)
  const [selectedChart, setSelectedChart] = useState<Nullable<any>>(null)
  const options = useAppSelector(selectOptions)

  useEffect(() => {
    setTimeout(() => {}, 3000)
  }, [])
  return knobData === undefined ? (
    <div>Loading ...</div>
  ) : (
    <div>
      <br />
      <br />

      {knobData && (
        <div className="main-panel">
          <div className="knob-container">
            <div className="knob">
              <Knob
                value={knobData.batteryKnob.vaLue}
                min={knobData.batteryKnob.min}
                max={knobData.batteryKnob.max}
                size={80}
                valueColor={knobData.batteryKnob.valueColor}
                readOnly
              />
              <div>Battery</div>
            </div>
            <div className="knob">
              <Knob
                value={knobData.autonomyKnob.vaLue}
                min={knobData.autonomyKnob.min}
                max={knobData.autonomyKnob.max}
                size={80}
                valueColor={knobData.autonomyKnob.valueColor}
                readOnly
              />
              <div>Autonomy</div>
            </div>
            <div className="knob">
              <Knob
                value={knobData.consumptionKnob.vaLue}
                min={knobData.consumptionKnob.min}
                max={knobData.consumptionKnob.max}
                size={80}
                valueColor={knobData.consumptionKnob.valueColor}
                readOnly
              />
              <div>Consumption</div>
            </div>
            <div className="knob">
              <Knob
                value={knobData.productionKnob.vaLue}
                min={knobData.productionKnob.min}
                max={knobData.productionKnob.max}
                size={80}
                valueColor={knobData.productionKnob.valueColor}
                readOnly
              />
              <div>Production</div>
            </div>
            <div className="knob">
              <Knob
                value={knobData.powerToGridKnob.vaLue}
                min={knobData.powerToGridKnob.min}
                max={knobData.powerToGridKnob.max}
                size={80}
                valueColor={knobData.powerToGridKnob.valueColor}
                readOnly
              />
              <div>Power to grid</div>
            </div>
            <div className="knob">
              <Knob
                value={knobData.powerFromGridKnob.vaLue}
                min={knobData.powerFromGridKnob.min}
                max={knobData.powerFromGridKnob.max}
                size={80}
                valueColor={knobData.powerFromGridKnob.valueColor}
                readOnly
              />
              <div>Power from grid</div>
            </div>
            <div className="weather-info-container">
              <div className="row day">{weatherInfo?.day}</div>

              <div className="row temperatures">
                Outdoor {weatherInfo?.outdoorTemperature} °C{" "}
              </div>

              <div className="row temperatures">
                {" "}
                Battery {weatherInfo?.batteryTemperature} °C
              </div>

              <div className="row times">
                <div>
                  <i className="wi wi-sunrise"></i> {weatherInfo?.sunrise}
                </div>
                <div>
                  <i className="wi wi-sunset"></i> {weatherInfo?.sunset}
                </div>
              </div>
              <div className="icon" style={{ fontSize: "2rem" }}>
                {
                  openWeatherWMOToEmoji(
                    weatherInfo?.weathercode,
                    weatherInfo?.daytime
                  ).value
                }
              </div>
            </div>
          </div>

          {!enlargedChart && (
            <div className="grid-container">
              <div className="chart">
                <div
                  className="enlarge-chart-button"
                  onClick={() => {
                    dispatch(setEnlargedChart(true))
                    setSelectedChart(batteryData)
                  }}
                >
                  +
                </div>

                <Chart type="line" data={batteryData} options={options} />
              </div>
              <div className="chart">
                <div
                  className="enlarge-chart-button"
                  onClick={() => {
                    dispatch(setEnlargedChart(true))
                    setSelectedChart(dailySumData)
                  }}
                >
                  +
                </div>
                <Chart type="line" data={dailySumData} options={options} />
              </div>
              <div className="chart">
                <div
                  className="enlarge-chart-button"
                  onClick={() => {
                    dispatch(setEnlargedChart(true))
                    setSelectedChart(gridThroughputData)
                  }}
                >
                  +
                </div>
                <Chart
                  type="line"
                  data={gridThroughputData}
                  options={options}
                />
              </div>
              <div className="chart">
                <div
                  className="enlarge-chart-button"
                  onClick={() => {
                    dispatch(setEnlargedChart(true))
                    setSelectedChart(wattThroughputData)
                  }}
                >
                  +
                </div>
                <Chart
                  type="line"
                  data={wattThroughputData}
                  options={options}
                />
              </div>
            </div>
          )}

          {enlargedChart && (
            <div className="chart large-chart">
              <div
                className="enlarge-chart-button"
                onClick={() => dispatch(setEnlargedChart(false))}
              >
                -
              </div>
              <Chart type="line" data={selectedChart} options={options} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MainPanel
