import { useEffect, useState } from "react"
import "./App.css"

import { useAppDispatch } from "./app/hooks"
import { useAxios } from "./utils/axios"

import MainPanel from "./components/MainPanel"

import {
  fetchBatteryChartData,
  fetchDailySumChartData,
  fetchGridThroughputChartData,
  fetchWattThroughputChartData,
} from "./features/Chart"
import { fetchKnobData, fetchWeatherData } from "./features/Knob"

function App() {
  const dispatch = useAppDispatch()
  const axios = useAxios()

  const getCurrentDate = () => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, "0")
    const day = String(currentDate.getDate()).padStart(2, "0")

    const formattedDate = `${year}-${month}-${day}`

    return formattedDate
  }

    const [currentDate, setCurrentDate] = useState(getCurrentDate())

  const fetchAPIData = async () => {
    if (axios.current) {
      dispatch(
        fetchBatteryChartData({
          axios: axios.current,
          url: `/${currentDate}/battery`,
        })
      )
      dispatch(
        fetchDailySumChartData({
          axios: axios.current,
          url: `/${currentDate}/dailySum`,
        })
      )
      dispatch(
        fetchWattThroughputChartData({
          axios: axios.current,
          url: `/${currentDate}/wattThroughput`,
        })
      )
      dispatch(
        fetchGridThroughputChartData({
          axios: axios.current,
          url: `/${currentDate}/gridThroughput`,
        })
      )
      dispatch(
        fetchKnobData({
          axios: axios.current,
          url: "/current",
        })
      )
      dispatch(
        fetchWeatherData({
          axios: axios.current,
          url: `/${currentDate}/ephemeris`,
        })
      )
    }
  }

  function useFetchData(fetchDataFunction: () => void) {
    useEffect(() => {
        setCurrentDate(getCurrentDate())
        fetchDataFunction()
        const interval = setInterval(fetchDataFunction, 300000)
        return () => clearInterval(interval)
    }, [fetchDataFunction])
  }
  useFetchData(fetchAPIData)

  return (
    <div className="App">
      <MainPanel />
    </div>
  )
}

export default App
